import React from "react"
import { Router, RouteComponentProps } from "@reach/router"
import {
  Authenticator,
  AuthenticatorProps,
  Button,
  Flex,
  Heading,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react"
import { Amplify } from "aws-amplify"
import Files from "../../components/dataroom/Files"

import config from "../../../site.config"

/** components */
import Layout from "../../components/LayoutFund"
import { theme } from "../../components/Theme"
import HeroVideoFund from "../../components/dynamic-sections/HeroVideoFund"

/** color theme */
const colorPalette = theme.colorPalettes.invest

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.GATSBY_FUND_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.GATSBY_FUND_COGNITO_USER_POOL_CLIENT_ID,
      identityPoolId: process.env.GATSBY_FUND_COGNITO_IDENTITY_POOL_ID,
    },
  },
})

const DataRoomContainer: React.FC = () => {
  const { route } = useAuthenticator((context) => [context.route])
  return (
    <Layout
      title="Dataroom| Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <Router basepath="/fund/dataroom">
        <PrivateRoute path="/" component={Files} />
      </Router>

      {route !== "authenticated" && (
        <>
          <HeroVideoFund title="" subtitle="" videoID="1" videoFile="/fund/video/login.mp4" />
          <HeroVideoFund title="" subtitle="" videoID="1" videoFile="/fund/video/login.mp4" />
        </>
      )}
    </Layout>
  )
}

/** const */
export const DataRoom: React.FC = () => {
  return (
    <Authenticator.Provider>
      <DataRoomContainer />
    </Authenticator.Provider>
  )
}

/** export */
export default DataRoom

const formFields = {
  signIn: {
    username: {
      placeholder: "john.appleseed@example.com",
    },
  },
}

const components: AuthenticatorProps["components"] = {
  SignIn: {
    Header() {
      const { tokens } = useTheme()

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      )
    },

    Footer() {
      const { toForgotPassword } = useAuthenticator()

      return (
        <Flex
          textAlign="center"
          direction="column"
          paddingLeft="var(--amplify-components-authenticator-form-padding)"
          paddingRight="var(--amplify-components-authenticator-form-padding)"
        >
          <Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link" className="mb-3">
            Reset Password
          </Button>
        </Flex>
      )
    },
  },
}

interface PrivateRouteProps extends RouteComponentProps {
  component: React.FC<RouteComponentProps>
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  return (
    <Authenticator
      loginMechanisms={["email"]}
      variation="modal"
      hideSignUp={true}
      components={components}
      formFields={formFields}
    >
      {({ signOut, user }) => <Component {...rest} />}
    </Authenticator>
  )
}
