import React, { useEffect, useRef, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { fetchAuthSession } from "aws-amplify/auth"
import { GetObjectCommand, ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3"
import { Breadcrumb, Button, Card, Table } from "react-bootstrap"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"
import { format } from "date-fns"
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"

class TreeNode {
  name: string
  path: string
  dateModified: Date
  size?: number
  children: TreeNode[]

  constructor(name: string, path: string, dateModified: Date, size?: number) {
    this.name = name
    this.path = path
    this.dateModified = dateModified
    this.size = size
    this.children = []
  }

  addChild(child: TreeNode): void {
    this.children.push(child)
  }

  toJson(): string {
    return JSON.stringify(this, null, 2)
  }

  get isFolder(): boolean {
    return this.path.endsWith("/")
  }

  get sizeFormatted(): string {
    if (this.isFolder) {
      return "-"
    } else {
      return formatBytes(this.size)
    }
  }

  get dateModifiedFormatted(): string {
    if (this.isFolder) {
      return "-"
    }

    return format(this.dateModified, "MM/dd/yyyy hh:mm:ss")
  }

  find(targetPath: string): TreeNode | undefined {
    if (this.path === targetPath) {
      return this
    }

    for (const child of this.children) {
      const foundNode = child.find(targetPath)
      if (foundNode) {
        return foundNode
      }
    }

    return undefined
  }
}

interface S3Object {
  Key?: string
  LastModified?: Date
  Size?: number
}

function buildTree(objects: S3Object[]): TreeNode {
  const tree: TreeNode = new TreeNode("__root", "", new Date())

  objects.forEach((object) => {
    if (object.Key == undefined) {
      return
    }
    const path = object.Key
    const parts = path.split("/").filter((part) => part !== "") // Filter out empty strings
    let currentLevel: TreeNode = tree

    parts.forEach((part) => {
      let existingNode = currentLevel.children.find((node) => node.name === part)

      if (!existingNode) {
        existingNode = new TreeNode(part, path, object.LastModified!, object.Size)
        currentLevel.addChild(existingNode)
      }

      currentLevel = existingNode
    })
  })

  return tree
}

function formatBytes(bytes: number): string {
  if (bytes < 1024) {
    return bytes + " Bytes"
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB"
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB"
  } else if (bytes < 1024 * 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB"
  } else {
    return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB"
  }
}

const Files: React.FC<RouteComponentProps> = (props) => {
  const rootTree = new TreeNode("__root", "", new Date())
  const rootTreeRef = useRef<TreeNode>(rootTree)
  const [tree, setTree] = useState<TreeNode>(rootTree)
  const [breadcrumbs, setBreadcrumbs] = useState<{ title: string; active: boolean; path: string }[]>()

  const listFiles = async () => {
    try {
      const { credentials } = await fetchAuthSession()
      const s3Client = new S3Client({
        region: "us-east-1",
        credentials: credentials,
      })
      const command = new ListObjectsV2Command({
        Bucket: "norhart-fund",
      })
      const response = await s3Client.send(command)
      const contents = response.Contents

      if (contents == undefined) {
        const tree = new TreeNode("__root", "", new Date())
        setTree(tree)
        rootTreeRef.current = tree
        return
      }

      const tree = buildTree(contents)

      rootTreeRef.current = tree
      setTree(tree)
      setBreadcrumbs([])
    } catch (error) {
      console.error(error)
    }
  }

  const handlePathChange = (path: string) => {
    const rootTree = rootTreeRef.current
    if (path == "" || path == undefined) {
      setTree(rootTree)
    } else {
      const node = rootTree.find(path)
      if (node != undefined) {
        setTree(node)
      }
    }

    updateBreadcrumbs(path)
  }

  const updateBreadcrumbs = (path: string) => {
    if (path == undefined) {
      setBreadcrumbs([])
      return
    }
    const breadcrumbs: { title: string; active: boolean; path: string }[] = []
    const parts = path.split("/").filter((part, i) => part != "")

    for (let index = 0; index < parts.length; index++) {
      const part = parts[index]

      breadcrumbs.push({
        title: part,
        active: index + 1 == parts.length ? true : false,
        path: parts.slice(0, index + 1).join("/") + "/",
      })
    }

    setBreadcrumbs(breadcrumbs)
  }

  const downloadFile = async (key: string) => {
    try {
      const { credentials } = await fetchAuthSession()
      const s3Client = new S3Client({
        region: "us-east-1",
        credentials: credentials,
      })
      const command = new GetObjectCommand({
        Bucket: "norhart-fund",
        Key: key,
      })
      const signedUrl = await getSignedUrl(s3Client, command, {
        expiresIn: 3600,
      })
      window.open(signedUrl, "_blank", "noreferrer")
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    listFiles()
  }, [])

  return (
    <section className="container-fluid pt-2" style={{ backgroundColor: "#f0f0f0" }}>
      <div className="container py-5">
        <div className="text-center">
          <NorhartBlackCircleIcon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center mb-3"
          style={{ fontSize: "15px", color: "#111111" }}
        >
          DATAROOM
        </div>

        <Card style={{ minHeight: "280px" }} className="shadow-none">
          <Card.Header className="ps-2">
            <Breadcrumb listProps={{ className: "mb-0" }}>
              <Breadcrumb.Item
                active={breadcrumbs == undefined || breadcrumbs?.length == 0}
                onClick={() => handlePathChange("")}
                linkProps={{ className: "text-decoration-none" }}
              >
                home
              </Breadcrumb.Item>
              {breadcrumbs?.map((breadcrumb, i) => {
                return (
                  <Breadcrumb.Item
                    key={breadcrumb.path}
                    active={breadcrumb.active}
                    onClick={() => handlePathChange(breadcrumb.path)}
                    linkProps={{ className: "text-decoration-none" }}
                  >
                    {breadcrumb.title}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          </Card.Header>

          <Table hover responsive className="text-nowrap">
            <thead>
              <tr>
                <th>Name</th>
                {/* <th className="text-end">Date modified</th> */}
                <th className="text-end">Size</th>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {tree.children.map((child, i) => {
                return (
                  <tr key={child.path}>
                    <td>
                      {child.isFolder ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-folder"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a2 2 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139q.323-.119.684-.12h5.396z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-file-earmark-text"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                          <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                        </svg>
                      )}{" "}
                      <a
                        href="#"
                        className="text-decoration-none"
                        onClick={(e) => {
                          e.preventDefault()
                          console.log(child)
                          if (child.isFolder) {
                            handlePathChange(child.path)
                          } else {
                            downloadFile(child.path)
                          }
                        }}
                      >
                        {child.name}
                      </a>
                    </td>
                    {/* <td className="text-end">{child.dateModifiedFormatted}</td> */}
                    {/* <td className="text-end">{child.isFolder ? "-" : formatBytes(child.size)}</td> */}
                    <td className="text-end">{child.sizeFormatted}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card>
      </div>
    </section>
  )
}

export default Files
